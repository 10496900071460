import { Button, Image } from 'antd'
import React, { useState } from 'react'
import { DrawerComp } from '../drawer/DrawerComp'
import { DrawerCompForms } from '../drawer/DrawerCompForms'
import { GiftOutlined } from '@ant-design/icons'
import pchelka from '../../images/pchelka.webp'
import pchelka2 from '../../images/pchelka2.webp'
import address from '../../images/img/erip.webp'
import svidetel from '../../images/img/svidetelstvo.webp'


export const FooterComp = () => {
	const [open, setOpen] = useState(false)

	const [placement, setPlacement] = useState('')
	const [title, setTitle] = useState('')
	const [isActiveForm, setIsActiveForm] = useState({
		tel: false,
		date: false,
		menu: false,
	})

	// function gtag_report_conversion(url) {
	// 	var callback = function () {};
	// 	window.gtag('event', 'conversion', {
	// 		'send_to': 'AW-11119129579/7lYiCID-wPoDEOvngbYp',
	// 		'event_callback': callback
	// 	});
	// 	return false;
	// }

	const showDrawer = (position, title, str) => {
		setOpen(true)
		setPlacement(position)
		setTitle(title)
		switch (str) {
			case 'tel': setIsActiveForm(prev => ({ ...prev, tel: true, date: false, menu: false }))
				break
			case 'date': setIsActiveForm(prev => ({ ...prev, tel: false, date: true, menu: false }))
				break
		}
	}



	return (
		<footer className='pt-12 pb-1 w-full z-10  bg-yellow-500 relative' id='contact'>
			<div className='container mx-auto text-center'>
				<div className=''>
					<h2 className='text-xl uppercase tracking-wider'>
						Ремонт<span className=''> бытовой техники</span> в Минске
					</h2>
				</div>
				<div className=''>
					<p className=' font-light text-sm'>Наши мастера имеют огромный опыт работы, а это профессиональная диагностика и качественный ремонт любой сложности с гарантией.</p>
				</div>

				{/* <div className='mt-10 mb-8'>
					<GiftOutlined className='text-orange-500 text-4xl' />
					<p className='text-white font-light mt-5'>
						Подарки каждому клиенту магнитики и средство для очистки
					</p>
				</div> */}

				<h3 className=' font-light uppercase'>Заказать услугу можно по телефону</h3>

				<div className=' mb-6 mt-5 flex flex-col'>
					<a
						href='tel:80339164101'
						className='text-3xl mb-3'
					// onClick={() => gtag_report_conversion('/')}
					>
						+375 (33) 916-41-01
					</a>
				</div>
				<div className=''>
					<p className='text-xs uppercase '>
						запишитесь заранее на определённое время и получите скидку<span className='font-semibold text-sm'> 15%</span> на ремонт Вашей техники.
					</p>
					<Button
						type='primary'
						className='uppercase'
						onClick={() => showDrawer('right', 'Заказать на дату', 'date')}
					>
						Записаться
					</Button>
				</div>

				{/* <aside className='mt-12 mb-6'>
					<h6 className='uppercase'>
						Наш адрес
					</h6>
					<a href='https://yandex.by/maps/-/CDR9ZJkf' target='_blank' className='mt-2'>
						Минск ул. Петра Глебки, 118
					</a>
				</aside> */}

			</div>

			<div className='mt-10'>
				<div className='container mx-auto mb-5'>
					<div className='flex sd:justify-center xyy:justify-between sd:flex-row xyy:flex-col'>
						<div className='mx-2'>
							<Image src={address} alt='Адрес' width={350} className='' />
						</div>
					</div>
				</div>

				<div className='mt-5 text-center'>
					<p className=''>
						Ссылка для оплаты
					</p>
					<a href='https://tiny.by/link/3d84340ae' target='_blank' className='text-blue-600'>
						https://tiny.by/link/3d84340ae
					</a>
				</div>
			</div>

			<div className='container mx-auto'>

				<div className='mt-10 text-center text-black font-light text-xs xyy:max-w-xs sd:max-w-2xl mx-auto'>
					<p className=''>
						ООО "ТРИОЗОН" УНП 193779735 БЕЛАРУСЬ, Г. МИНСК, УЛ. СКРЫГАНОВА, ДОМ 6, ОФ. 7, ПОМ., 220073 BY94ALFA30122F35840010270000 Карт-счет
						ЗАО "Альфа-банк" ALFABY2X
					</p>
					<p className='mt-4'>
						Директор: Крук Дмитрий Михайлович
					</p>
					<p className='mt-4'>
						Работает на основании устава
					</p>

					<div className='mt-7'>
						<Image src={svidetel} alt='Свидетельство' width={250} className='' />
					</div>
				</div>

				<div className='absolute bottom-40 xyy:left-5 sd:left-28'>
					<Image src={pchelka} alt='Символ компании' width={60} />
				</div>
				<div className='absolute bottom-10 xyy:right-5 sd:right-20'>
					<Image src={pchelka2} alt='Символ компании' width={55} />
				</div>

				<div className="
    w-full 
    flex
    justify-center
    items-center 
    md:flex-row
    flex-col 
    sm:pt-4 
    xyy:pt-1 
    border-t-[1px]
    border-t-[#3F3E45]
	 mt-10
    ">
					<p className="
      font-poppins
      font-light
      text-center
      sm:text-sm
      xyy:text-[10px]
    text-black/70
		mb-0
      ">
						Copyright Ⓒ 2018-2024. Разработка и продвижение
						<a href="https://vi-tech.by" className="text-blue-700" target="_blank"> VI:TECH</a>
						. &nbsp; Все права защищены. Информация на сайте не является публичной офертой.
					</p>
				</div>

				<DrawerCompForms open={open} placement={placement} title={title} setOpen={setOpen} isActiveForm={isActiveForm} />
			</div>
		</footer>
	)
}
